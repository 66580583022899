import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { locationContext } from "../Context/LocationContext";
function ProductMileage() {
  const { t } = useTranslation(["Product"]);
  const [tabs, setTabs] = useState(0);
  const { accessToBuyProduct } = useContext(locationContext);
  const tab = [
    <div class="tab-content active" id="tab-1">
      <di className="right-content">
        <div className="key-features-product">
          <h5>
            <span className="product-booster">
              {t("Product2.howtouse.title")}
            </span>
          </h5>
          <div className="applications">
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>
                  <span className="span-tag">
                    {t("Product2.howtouse.fuel")}&nbsp;
                  </span>
                  &nbsp;{t("Product2.howtouse.paraone")}
                </p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>&nbsp;{t("Product2.howtouse.paratwo")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>
                  <span className="span-tag">
                    {t("Product2.howtouse.eff")}&nbsp;
                  </span>
                  &nbsp;{t("Product2.howtouse.parathree")}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="applications">
          <h5>
            <span className="product-booster">
              {t("Product2.benefits.title")}
            </span>
          </h5>
          <ul>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product2.benefits.paraone")}</p>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product2.benefits.paratwo")}</p>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product2.benefits.parathree")}</p>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product2.benefits.parafour")}</p>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product2.benefits.parafive")}</p>
              </div>
            </li>
          </ul>
        </div>

        <div className="applications">
          <h5>
            <span className="product-booster">
              {t("Product2.cautions.title")}
            </span>
          </h5>
          <ul>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product2.cautions.one")}</p>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product2.cautions.two")}</p>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product2.cautions.three")}</p>
              </div>
            </li>
          </ul>
        </div>
      </di>
    </div>,
    <div class="tab-content active" id="tab-2">
      <div className="right-content">
        <div className="key-features-product">
          <h5>
            <span className="product-booster">
              {t("Product2.application.title")}
            </span>
          </h5>
          <div className="row">
            <div className="col-md-6">
              <div className="applications">
                <h6> {t("Product2.application.sub1.subb1.title")}</h6>
                <ul>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p> {t("Product2.application.sub1.subb1.para")}</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="applications">
                <h6> {t("Product2.application.sub1.subb2.title")}</h6>
                <ul>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p> {t("Product2.application.sub1.subb2.para")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p> {t("Product2.application.sub1.subb2.para1")}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="applications">
                <h6> {t("Product2.application.sub2.title")}</h6>
                <ul>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub2.paraone")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub2.paratwo")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub2.parathree")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub2.parafour")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub2.parafive")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub2.parasix")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub2.paraseven")}</p>
                    </div>
                  </li>

                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub2.paraeight")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub2.paranine")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub2.paraten")}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <div className="applications">
                <h6> {t("Product2.application.sub3.title")}</h6>
                <ul>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub3.paraone")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub3.paratwo")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub3.parathree")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub3.parafour")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub3.parafive")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub3.parasix")}</p>
                    </div>
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub3.paraseven")}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="applications">
                <h6> {t("Product2.application.sub4.title")}</h6>
                <ul>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                    <div>
                      <p>{t("Product2.application.sub4.paraone")}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
  ];

  const navigate = useNavigate();
  const clickTab = (e) => {
    setTabs(e.target.id);
  };
  return (
    <>
      {" "}
      <Header />
      <section className="privacy-area pt-100">
        <div className="container" style={{ position: "relative" }}>
          <div className="privacy-item ">
            <div className="row mt-5">
              <div
                className="col-lg-5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="container-product"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/products/product.mileage.png"
                    alt="img"
                    width="100%"
                  />
                </div>
                {/* {accessToBuyProduct && (
                  <div className="container-product mt-5">
                    <div
                      className="box__link button-animation"
                      onClick={() =>
                        toast.warning(
                          "This Product is currently out of stock!!",
                          {
                            autoClose: 2000,
                          }
                        )
                      }
                    >
                      {t("Product2.button.buynow")}
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="col-lg-7 tirrent-booster">
                <h2>
                  <span className="product-booster">
                    {" "}
                    {t("Product2.span")}{" "}
                  </span>
                </h2>
                <h2> {t("Product2.title")} </h2>
                <p>{t("Product2.description")}</p>
                <div className="row">
                  <div className="col-md-12">
                    <div className="right-content keyfeatures">
                      <ul>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product2.keyfeatures.paraone")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product2.keyfeatures.paratwo")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product2.keyfeatures.parathree")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product2.keyfeatures.parafour")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product2.keyfeatures.parafive")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product2.keyfeatures.parasix")}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs-container">
              <div class="tabs">
                <div class="tab-links">
                  <button
                    class={tabs == "0" ? `tab-link active` : `tab-link`}
                    onClick={(e) => clickTab(e)}
                    id="0"
                  >
                    <i class="fas fa-info-circle"></i> {t("usage")}
                  </button>
                  <button
                    class={tabs == "1" ? `tab-link active` : `tab-link`}
                    onClick={(e) => clickTab(e)}
                    id="1"
                  >
                    <i class="fas fa-list"></i> {t("application")}
                  </button>
                </div>
                {tab[tabs]}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductMileage;
