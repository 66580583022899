import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { locationContext } from "../Context/LocationContext";
function ProductBooster() {
  const { t } = useTranslation(["Product"]);
  const { accessToBuyProduct } = useContext(locationContext);
  const [tabs, setTabs] = useState(0);
  const tab = [
    <div class="tab-content" id="tab-1">
      <div className="right-content">
        <div className="key-features-product">
          <h5>
            <span className="product-booster">
              {t("Product1.benefits.title")}
            </span>
          </h5>
          <div className="applications">
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <div>
                  <p>{t("Product1.directionofuse.one")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <div>
                  <p>{t("Product1.directionofuse.two")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <div>
                  <p>{t("Product1.directionofuse.three")}</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="applications">
            <h6> {t("Product1.benefits.four")}</h6>
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <div>
                  <p>{t("Product1.benefits.parafive")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <div>
                  <p>{t("Product1.benefits.parafour")}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="key-features-product">
          <h5>
            <span className="product-booster">
              {t("Product1.cautions.title")}
            </span>
          </h5>
          <div className="applications">
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <div>
                  <p>{t("Product1.cautions.one")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <div>
                  <p>{t("Product1.cautions.two")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <div>
                  <p>{t("Product1.cautions.three")}</p>
                </div>
              </li>

              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <div>
                  <p>{t("Product1.cautions.four")}</p>
                </div>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <div>
                  <p>{t("Product1.cautions.five")}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>,

    <div class="tab-content" id="tab-2">
      <div className="right-content">
        <div className="row">
          <div className="col-md-6 key-features-product">
            <h5>
              <span className="product-booster">
                {t("Product1.application.title")}
              </span>
            </h5>
            <div className="applications">
              <h6> {t("Product1.application.sub1.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub1.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub1.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub1.parathree")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub1.parafour")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub1.parafive")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub1.parasix")}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="applications">
              <h6> {t("Product1.application.sub2.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.parathree")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.parafour")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.parafive")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.parasix")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.paraseven")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.paraeight")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.paranine")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.paraten")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub2.paraeleven")}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="applications">
              <h6> {t("Product1.application.sub3.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub3.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub3.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub3.parathree")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub3.parafour")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub3.parafive")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub3.parasix")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub3.paraseven")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub3.paraeight")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub3.paranine")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub3.paraten")}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 key-features-product">
            <div className="applications">
              <h6> {t("Product1.application.sub4.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub4.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub4.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub4.parathree")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub4.parafour")}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="applications">
              <h6> {t("Product1.application.sub5.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub5.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub5.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub5.parathree")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub5.parafour")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub5.parafive")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub5.parasix")}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="applications">
              <h6> {t("Product1.application.sub6.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub6.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub6.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub6.parathree")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub6.parafour")}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="applications">
              <h6> {t("Product1.application.sub7.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub7.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub7.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product1.application.sub7.parathree")}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>,
  ];
  const navigate = useNavigate();
  const clickTab = (e) => {
    setTabs(e.target.id);
  };

  return (
    <>
      <Header />
      <section className="privacy-area pt-100">
        <div className="container" style={{ position: "relative" }}>
          <div className="privacy-item ">
            <div className="row mt-5">
              <div
                className="col-lg-5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="container-product"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {/* <div className=""> */}
                  {/* <div className="imgBx"> */}
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/products/product.tirrentbooster.png"
                    alt="img"
                    width="100%"
                  />
                  {/* </div> */}
                  {/* </div> */}
                </div>
                {accessToBuyProduct && (
                  <div className="container-product mt-5">
                    <div
                      className="box__link button-animation"
                      // onClick={() => navigate("/product-details")}
                    >
                      <a
                        href="https://www.flipkart.com/tirrent-global-tg0001tb-booster-engine-treatment-high-performance-oil/p/itm52789ccd70f75?pid=VLUGWNHJSUKEEYV9&lid=LSTVLUGWNHJSUKEEYV9NPZJDO&marketplace=FLIPKART&q=tirrent+global&store=1mt%2F0mi&srno=s_1_1&otracker=AS_QueryStore_OrganicAutoSuggest_1_8_na_na_ps&otracker1=AS_QueryStore_OrganicAutoSuggest_1_8_na_na_ps&fm=search-autosuggest&iid=509ea9d6-ad89-465f-a092-5e7d8e81929a.VLUGWNHJSUKEEYV9.SEARCH&ppt=sp&ppn=sp&ssid=w50zxi82og0000001734007004619&qH=c582a6a15d49a6dd"
                        target="_blank"
                      >
                        {t("Product1.button.buynow")}
                      </a>

                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-7 tirrent-booster">
                <h2>
                  <span className="product-booster">
                    {" "}
                    {t("Product1.span")}{" "}
                  </span>
                </h2>
                <h2> {t("Product1.title")} </h2>
                <p>{t("Product1.description")}</p>
                <div className="row">
                  <div className="col-md-8">
                    <div className="right-content keyfeatures">
                      <ul>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product1.keyfeatures.one")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product1.keyfeatures.two")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product1.keyfeatures.three")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product1.keyfeatures.four")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product1.keyfeatures.five")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product1.keyfeatures.six")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product1.keyfeatures.seven")}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs-container">
              <div class="tabs">
                <div class="tab-links">
                  <button
                    class={tabs == "0" ? `tab-link active` : `tab-link`}
                    onClick={(e) => clickTab(e)}
                    id="0"
                  >
                    <i class="fas fa-info-circle"></i>
                    {t("Product.usage")}
                  </button>
                  <button
                    class={tabs == "1" ? `tab-link active` : `tab-link`}
                    onClick={(e) => clickTab(e)}
                    id="1"
                  >
                    <i class="fas fa-list"></i> {t("Product.applications")}
                  </button>
                </div>
                {tab[tabs]}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductBooster;
