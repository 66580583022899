import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function OilAnalysis() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["OilAnalysis"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            {/* <h2 className="policy">{t("blog1.title")}</h2> */}
            <div className="container">
              <div className="title-item">
                <h2>{t("oil.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-10">
              <div className="oil-analysis">
                <p>{t("oil.para")}</p>
                <h6 className="mt-5">{t("oil.que1.q")}</h6>
                <p className="mt-5">{t("oil.que1.ans")}</p>
                <p>{t("oil.que1.1")}</p>
                <h6 className="mt-5">{t("oil.que1.why")}</h6>
                <p>{t("oil.que1.whypara")}</p>
                <p className="mt-5">{t("oil.que1.2")}</p>
                 <p>&nbsp;&nbsp;&nbsp;&nbsp;{t("oil.que1.b")}</p>
                 <p className="mt-5">{t("oil.que1.2para")}</p>
                 <p className="mt-5">{t("oil.que1.3para")}</p>
                 <p className="mt-5">{t("oil.que1.4.1para")}</p>
                 <p>{t("oil.que1.4.2para")}</p>
                 <h6 className="mt-5">{t("oil.procedure.title")}</h6>
                 <p>{t("oil.procedure.para")}</p>
                 <p className="mt-5">{t("oil.procedure.1")}</p>
                 <p>{t("oil.procedure.1.1")}</p>
                 <p>{t("oil.procedure.1.2")}</p>
                 <p>{t("oil.procedure.1.3")}</p>
                 <p>{t("oil.procedure.1para")}</p>
                 <p className="mt-5">{t("oil.procedure.2")}</p>
                 <p>{t("oil.procedure.2.1")}</p>
                 <p className="mt-5">{t("oil.procedure.2.2")}</p>
                 <p className="mt-5">{t("oil.procedure.3")}</p>
                 <p className="mt-5">{t("oil.procedure.4")}</p>
                 <h6 className="mt-5">{t("oil.procedure.result")}</h6>
                 <p className="mt-5">{t("oil.procedure.result1")}</p>
                 <p >{t("oil.procedure.result2")}</p>
                 <p className="mt-5">{t("oil.procedure.result3")}</p>
                 <h6 className="mt-5">{t("oil.que2.q")}</h6>
                 <p className="mt-5">{t("oil.que2.para")}</p>
                 <h6 className="mt-5">{t("oil.que3.q")}</h6>
                 <p>{t("oil.que3.para")}</p>
                 <p>{t("oil.que3.para1")}</p>
                 <img className="mt-5" src="/SiteTemplate/TirrentGlobal/assets/img/oil.jpeg"/>
                 <h6 className="mt-5">{t("oil.Conclusion.cons")}</h6>
                 <p>{t("oil.Conclusion.para")}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OilAnalysis;
