import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import { locationContext } from "../Context/LocationContext";
function ProductGrease() {
  const { t } = useTranslation(["Product"]);
  const { accessToBuyProduct } = useContext(locationContext);
  const [tabs, setTabs] = useState(0);
  const tab = [
    <div class="tab-content active" id="tab-1">
      <div className="right-content">
        <div className="key-features-product">
          <h5>
            {" "}
            <span className="product-booster">
              {" "}
              {t("Product4.howtouse.title")}
            </span>
          </h5>
          <div className="applications">
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
               <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>{t("Product4.howtouse.one")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
               <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>{t("Product4.howtouse.two")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
               <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>{t("Product4.howtouse.three")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
               <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>{t("Product4.howtouse.four")}</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="applications">
          <h5>
            <span className="product-booster">
              {t("Product4.directionofuse.title")}
            </span>
          </h5>
          <ul>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
             <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product4.directionofuse.one")}</p>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
             <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product4.directionofuse.two")}</p>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
             <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product4.directionofuse.three")}</p>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
             <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product4.directionofuse.four")}</p>
              </div>
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
             <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
              <div>
                <p>{t("Product4.directionofuse.five")}</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="applications left-content">
          <h5>
            <span className="product-booster">
              {t("Product4.benefits.title")}
            </span>
          </h5>
          <ul>
            <div className="row">
              <div className="col-md-12 key-features-product">
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-droplet"></i>{" "}
                  <div>
                    <h6
                      style={{
                        marginBottom: "0",
                        color: "#f99f36",
                      }}
                    >
                      {" "}
                      {t("Product4.benefits.one")}
                    </h6>
                    <p>{t("Product4.benefits.paraone")}</p>
                  </div>
                </li>
              </div>
              <div className="col-md-12 key-features-product">
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-droplet"></i>{" "}
                  <div>
                    <h6
                      style={{
                        marginBottom: "0",
                        color: "#f99f36",
                      }}
                    >
                      {" "}
                      {t("Product4.benefits.two")}
                    </h6>
                    <p>{t("Product4.benefits.paratwo")}</p>
                  </div>
                </li>
              </div>
              <div className="col-md-12 key-features-product">
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-droplet"></i>{" "}
                  <div>
                    <h6
                      style={{
                        marginBottom: "0",
                        color: "#f99f36",
                      }}
                    >
                      {" "}
                      {t("Product4.benefits.three")}
                    </h6>
                    <p>{t("Product4.benefits.parathree")}</p>
                  </div>
                </li>
              </div>
              <div className="col-md-12 key-features-product">
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-droplet"></i>{" "}
                  <div>
                    <h6
                      style={{
                        marginBottom: "0",
                        color: "#f99f36",
                      }}
                    >
                      {" "}
                      {t("Product4.benefits.four")}
                    </h6>
                    <p>{t("Product4.benefits.parafour")}</p>
                  </div>
                </li>
              </div>
            </div>
          </ul>
        </div>
        <div className="key-features-product">
          <h5>
            {" "}
            <span className="product-booster">
              {" "}
              {t("Product4.cautions.title")}
            </span>
          </h5>
          <div className="applications">
            <ul>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
               <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>{t("Product4.cautions.one")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
               <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>{t("Product4.cautions.two")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
               <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>{t("Product4.cautions.three")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
               <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>{t("Product4.cautions.four")}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
               <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                <p>{t("Product4.cautions.five")}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>,
    <div class="tab-content" id="tab-2">
      <div className="right-content">
       
          <div className="key-features-product">
            <h5>
              <span className="product-booster">
                {t("Product4.application.title")}
              </span>
            </h5>
            <div className="row">
              <div className="col-md-6">
            <div className="applications">
              <h6> {t("Product4.application.sub1.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-circle"></i>
                  <div>
                    <p>{t("Product4.application.sub1.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-circle"></i>
                  <div>
                    <p>{t("Product4.application.sub1.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-circle"></i>
                  <div>
                    <p>{t("Product4.application.sub1.parathree")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i className="fa-solid fa-circle"></i>
                  <div>
                    <p>{t("Product4.application.sub1.parafour")}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="applications">
              <h6> {t("Product4.application.sub2.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub2.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub2.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub2.parathree")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub2.parafour")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub2.parafive")}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="applications">
              <h6> {t("Product4.application.sub4.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.parathree")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.parafour")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.parafive")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.parasix")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.paraseven")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.paraeight")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.paranine")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.paraten")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.paraeleven")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub4.paratwelve")}</p>
                  </div>
                </li>
              </ul>
            </div>
            </div>
        
          <div className="col-md-6">
            <div className="applications">
              <h6> {t("Product4.application.sub3.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub3.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub3.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub3.parathree")}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="applications">
              <h6> {t("Product4.application.sub5.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub5.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub5.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub5.parathree")}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="applications">
              <h6> {t("Product4.application.sub6.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub6.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub6.paratwo")}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="applications">
              <h6> {t("Product4.application.sub7.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub7.paraone")}</p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="applications">
              <h6> {t("Product4.application.sub8.title")}</h6>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub8.paraone")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub8.paratwo")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub8.parathree")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub8.parafour")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub8.parafive")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub8.parasix")}</p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                 <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                  <div>
                    <p>{t("Product4.application.sub8.paraseven")}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>,
  ];

  const navigate = useNavigate();
  const clickTab = (e) => {
    setTabs(e.target.id);
  };
  return (
    <>
      <Header />
      <section className="privacy-area pt-100">
        <div className="container" style={{ position: "relative" }}>
          <div className="privacy-item ">
            <div className="row mt-5">
              <div
                className="col-lg-5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="container-product"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {/* <div className=""> */}
                  {/* <div className="imgBx"> */}
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/products/product.grease.png"
                    alt="img"
                    width="60%"
                  />
                  {/* </div> */}
                  {/* </div> */}
                </div>
                {/* {accessToBuyProduct && (
                  <div className="container-product mt-5">
                    <div
                      className="box__link button-animation"
                      onClick={() =>
                        toast.warning(
                          "This Product is currently out of stock!!",
                          {
                            autoClose: 2000,
                          }
                        )
                      }
                    >
                      {t("Product4.button.buynow")}
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="col-lg-7 tirrent-booster">
                <h2>
                  <span className="product-booster">
                    {" "}
                    {t("Product4.span")}{" "}
                  </span>
                </h2>
                <h2> {t("Product4.title")} </h2>
                <p>{t("Product4.description")}</p>
                <div className="row">
                  <div className="col-md-12">
                    <div className="right-content keyfeatures">
                      <ul>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                         <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product4.keyfeatures.one")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                         <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product4.keyfeatures.two")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                         <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product4.keyfeatures.three")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                         <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product4.keyfeatures.four")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                         <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product4.keyfeatures.five")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                         <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product4.keyfeatures.six")}</p>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                         <i className="fa fa-check" style={{backgroundColor:"#f99f36", padding:"1px", borderRadius:"50%", color:"#fff"}}></i>{" "}
                          <div>
                            <p>{t("Product4.keyfeatures.seven")}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs-container">
              <div class="tabs">
                <div class="tab-links">
                  <button
                    class={tabs == "0" ? `tab-link active` : `tab-link`}
                    onClick={(e) => clickTab(e)}
                    id="0"
                  >
                    <i class="fas fa-info-circle"></i> {t("Product.usage")}
                  </button>
                  <button
                    class={tabs == "1" ? `tab-link active` : `tab-link`}
                    onClick={(e) => clickTab(e)}
                    id="1"
                  >
                    <i class="fas fa-list"></i>  {t("Product.applications")}
                  </button>
                </div>
                {tab[tabs]}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductGrease;
