import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function ResearchPage() {
  const { t } = useTranslation(["research"]);
  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area main-bg">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>{t("ResearchHeading.title")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-area">
        <div className="container">
          <div className="row align-iems-center">
            <div className="col-lg-6">
              <div className="section-title">
                <h1 className="research-heading">
                  {t("researchHeading.title1")}
                </h1>
              </div>
              <div className="about-content">
                <p>{t("researchHeading.line2")}</p>
                <h4 style={{ color: "#F99F36" }}>
                  {t("researchHeading.title3")}
                </h4>

                <p>{t("researchHeading.line3")}</p>
                <p>{t("researchHeading.line5")}</p>

              </div>
            </div>
            <div className="col-lg-6 research-development">
              <div className="about-img-wrap">
                <div className="about-img-slider owl-theme">
                  <div className="about-img-item">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/tecno-page.png"
                      alt="About"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area">
        <div className="about-content">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h4 style={{ color: "#F99F36" }}>
                  {t("researchHeading.title4")}
                </h4>
                <p>{t("researchHeading.line4")}</p>

                <ul>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>
                    {t("researchHeading.subheading1.title")}
                    <p className="para">
                      {t("researchHeading.subheading1.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading2.title")}
                    <p className="para">
                      {t("researchHeading.subheading2.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading3.title")}
                    <p className="para">
                      {t("researchHeading.subheading3.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading4.title")}
                    <p className="para">
                      {t("researchHeading.subheading4.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading5.title")}
                    <p className="para">
                      {t("researchHeading.subheading5.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading6.title")}
                    <p className="para">
                      {t("researchHeading.subheading6.description")}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 set-order">
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/bf_AF.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-sm-6 col-lg-6 mt-5">
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/after.jpg"
              alt="Project Details"
            />
          </div>
          <div className="col-sm-6 col-lg-6 mt-5">
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/before-cell.jpg"
              alt="Project Details"
            />
          </div>
        </div>
      </div>
      <div className="page-title-area main-bg" style={{ display: "flex" }}>
        <div className="title-item quote" style={{ padding: "30px" }}>
          <h2 >{t("quote.title")}</h2>
        </div>
      </div>

      <div className="project-details-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title mt-5">
                <h1 className="research-heading">{t("quote.industries.title")}</h1>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6 p-2  ">
                  <div class="card12">
                    <div class="image-container">
                      <img src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/Automotive.jpg" alt="Card Image" />
                      <div class="hover-content">
                        {/* <p>This is the hidden content that appears on hover!</p> */}
                      </div>
                    </div>
                    <h2 class="card-heading">{t("quote.industries.one")}</h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 p-2  ">
                  <div class="card12">
                    <div class="image-container">
                      <img src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/industrial.jpg" alt="Card Image" />
                      <div class="hover-content">
                        {/* <p>This is the hidden content that appears on hover!</p> */}
                      </div>
                    </div>
                    <h2 class="card-heading">{t("quote.industries.two")}</h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 p-2  ">
                  <div class="card12">
                    <div class="image-container">
                      <img src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/construction.jpg" alt="Card Image" />
                      <div class="hover-content">
                        {/* <p>This is the hidden content that appears on hover!</p> */}
                      </div>
                    </div>
                    <h2 class="card-heading">{t("quote.industries.three")}</h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 p-2  ">
                  <div class="card12">
                    <div class="image-container">
                      <img src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/mining.jpg" alt="Card Image" />
                      <div class="hover-content">
                        {/* <p>This is the hidden content that appears on hover!</p> */}
                      </div>
                    </div>
                    <h2 class="card-heading">{t("quote.industries.four")}</h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 p-2  ">
                  <div class="card12">
                    <div class="image-container">
                      <img src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/oil and gas.jpg" alt="Card Image" />
                      <div class="hover-content">
                        {/* <p>This is the hidden content that appears on hover!</p> */}
                      </div>
                    </div>
                    <h2 class="card-heading">{t("quote.industries.five")}</h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 p-2  ">
                  <div class="card12">
                    <div class="image-container">
                      <img src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/Marine.jpg" alt="Card Image" />
                      <div class="hover-content">
                        {/* <p>This is the hidden content that appears on hover!</p> */}
                      </div>
                    </div>
                    <h2 class="card-heading">{t("quote.industries.six")}
                    </h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 p-2  ">
                  <div class="card12">
                    <div class="image-container">
                      <img src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/agriculture.jpg" alt="Card Image" />
                      <div class="hover-content">
                        {/* <p>This is the hidden content that appears on hover!</p> */}
                      </div>
                    </div>
                    <h2 class="card-heading">{t("quote.industries.seven")}</h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 p-2  ">
                  <div class="card12">
                    <div class="image-container">
                      <img src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/Military.jpg" alt="Card Image" />
                      <div class="hover-content">
                        {/* <p>This is the hidden content that appears on hover!</p> */}
                      </div>
                    </div>
                    <h2 class="card-heading">{t("quote.industries.eight")}</h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 p-2  ">
                  <div class="card12">
                    <div class="image-container">
                      <img src="/SiteTemplate/TirrentGlobal/assets/img/we-serve/Railway.jpg" alt="Card Image" />
                      <div class="hover-content">
                        {/* <p>This is the hidden content that appears on hover!</p> */}
                      </div>
                    </div>
                    <h2 class="card-heading">{t("quote.industries.nine")}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResearchPage;
